<template>
  <cui-acl :roles="['underDevelopment']" redirect>
    <div>
      <!-- Filters -->
      <period-filter @filter-change="handleFilterChange" />
      <!-- Display imports -->
      <a-form-item label="timer i måned">
        <a-input v-model="hoursInMonth" type="number" style="width: 200px"/>
      </a-form-item>
      <a-spin :spinning="loading">
        <a-table bordered :data-source="dataSource" :columns="columns">
        </a-table>
        <a-button @click="downloadData()">
          Download Data
        </a-button>
        <a-button @click="importData()">
          Generate Transactions
        </a-button>
      </a-spin>
    </div>
  </cui-acl>
</template>

<script>
import { firestore } from '@/services/firebase'
import { openNotification } from '@/services/powerAutomate'

import PeriodFilter from '@/components/custom/payroll/filters'
import moment from 'moment'
import cuiAcl from '@/components/cleanui/system/ACL'
import exportFromJSON from 'export-from-json'

// import { salaryTypes } from '@/services/common/hr'

function chunkArray(array, chunkSize) {
  const chunks = []
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize))
  }
  return chunks
}

export default {
  components: {
    PeriodFilter,
    cuiAcl,
  },
  data() {
    return {
      dataSource: [],
      projects: [],
      loading: false,
      hoursInMonth: 160,
      period: '',
      columns: [
        {
          title: 'Ansatt',
          dataIndex: 'fullName',
        },
        {
          title: 'Stilling %',
          dataIndex: 'employmentPercentage',
        },
        {
          title: 'Prosjekt',
          dataIndex: 'project',
        },
        {
          title: 'Totalt timer',
          dataIndex: 'totalHours',
        },
        {
          title: 'Garanti',
          dataIndex: 'guaranteeSalary',
        },
      ],
    }
  },
  mounted() {
    // get timesheetssettings from firestore
    firestore.collection('settings').doc('timesheet').get()
      .then(doc => {
        this.activities = doc.data().activities
      })
    // this.getData()
  },
  methods: {
    moment,
    async importData() {
      this.loading = true
      // add validation
      try {
        const data = this.dataSource

        // Split data into chunks
        const chunkSize = 500
        const dataChunks = chunkArray(data, chunkSize)

        // Process each chunk
        for (const chunk of dataChunks) {
          // Initialize batch
          const batch = firestore.batch()

          // Add transactions with payrollImportDocRef to batch
          chunk.forEach((line) => {
            if (!line.projectID) {
              openNotification(`Employee ${line.fullName} has no project`, 'error')
              return
            }
            const nLine = {
              employeeID: line.employeeID,
              employeeName: line.fullName,
              createdDate: moment().format('YYYY-MM-DDTHH:mm'),
              type: line.salaryType,
              amount: line.guaranteeSalary,
              department: line.department,
              project: line.projectID,
              period: this.period,
              status: 'unpaid',
            }
            const transactionDocRef = firestore.collection('payrollTransactions').doc()
            batch.set(transactionDocRef, nLine)
          })

          // Commit the batch
          await batch.commit()
        }

        openNotification('Fixed salaries imported into payrollTransactions')
      } catch (err) {
        console.log(err)
        openNotification('Error importing fixed salaries', 'error')
      } finally {
        this.loading = false
      }
    },
    downloadData() {
      const data = this.dataSource
      const fileName = 'guaranteedSalary'
      const exportType = exportFromJSON.types.csv
      const delimiter = ';'
      exportFromJSON({ data, fileName, exportType, delimiter })
    },
    handleFilterChange(filters) {
      this.getData(filters)
    },
    async getData(filters) {
      this.loading = true
      this.period = filters.period
      const data = []
      const hours = []
      this.dataSource = []
      const startOfMonth = moment(filters.period, 'YYYYMM').startOf('month').format('YYYY-MM-DD')
      const endOfMonth = moment(filters.period, 'YYYYMM').endOf('month').format('YYYY-MM-DD')

      await firestore.collection('timesheets')
        .where('date', '>=', startOfMonth)
        .where('date', '<=', endOfMonth)
        .get()
        .then(snapshot => {
          snapshot.forEach(async doc => {
            const timesheet = doc.data()
            hours.push({
              employeeID: timesheet.employeeID,
              hours: timesheet.hours,
              activity: timesheet.activity,
              withoutPayment: this.activities.find(a => a.value === timesheet.activity).withoutPayment,
            })
          })
        })
      // insert hours into dataSource. each object of dataSource should have be the sum of all hours for each employee
      const employees = [...new Set(hours.map(item => item.employeeID))]
      employees.forEach((employeeID, i) => {
        const employeeHours = hours.filter(item => item.employeeID === employeeID)
        const totalHours = employeeHours.reduce((acc, curr) => {
          if (curr.withoutPayment) return acc
          return acc + Number(curr.hours)
        }, 0)
        data.push({
          employeeID: employeeID,
          totalHours,
        })
      })
      data.forEach(async (employee, i) => {
        const employeeDoc = await firestore.collection('users').where('employeeID', '==', employee.employeeID).get().then(snapshot => snapshot.docs[0])
        // position is last object of array position
        const position = employeeDoc.data().positions[employeeDoc.data().positions.length - 1]
        data[i].fullName = employeeDoc.data().fullName
        data[i].project = employeeDoc.projectID
        data[i].department = position.department
        if (!employeeDoc.data().positions) return
        data[i].employmentPercentage = position.percentage
        data[i].salaryType = position.salary.type
        data[i].salaryAmount = position.salary.amount
        data[i].guaranteeSalary = this.calculateFixedSalary(employee.totalHours, this.hoursInMonth, position.salary)
        this.dataSource.push(data[i])
      })
      // order dataSource by fullName
      this.loading = false
    },
    calculateFixedSalary(totalHours, hoursInMonth, salary) {
      // calculate guarantee salary for each employee
      // guarantee salary is the sum of all hours worked by employee in a month multiplied by the hourly rate
      // guarantee salary is only calculated for employees with fixed monthly salary
      if (totalHours === 0) return 0
      if (!salary.amount) return 0
      if (!salary.type) return 0
      if (salary.type === 'fixedMonthly' || salary.type === 'guaranteeMonthly') {
        const calc = (Number(totalHours) / Number(hoursInMonth)) * Number(salary.amount)
        if (calc > Number(salary.amount)) return Number(salary.amount)
        return calc
      }
      if (salary.type === 'hourly' || salary.type === 'guaranteeHourly') return Number(totalHours) * Number(salary.amount)
      return 0
    },
  },
}
</script>
